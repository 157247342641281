const orderingLayer = (params) => {
  const btn = document.getElementById('reodering-layer')
  let indice = 0
  if (btn) {
    btn.addEventListener('click', (event) => {
      const btns = document.querySelectorAll('.btns-tilsets')
      const listSourceLayer = []
      btns.forEach((btn) => {
        const visible = map.getLayoutProperty(`${btn.dataset.tilsetName}-id`, 'visibility')
        if (visible === 'visible') {
          listSourceLayer.push(`${btn.dataset.tilsetName}-id`)
        }
      })
      if (listSourceLayer.length >= 1) {
        moveLayer(listSourceLayer, indice)
      }
      if (((indice) >= listSourceLayer.length - 1)) {
        indice = 0
      } else {
        indice = indice + 1
      }
    })

  }
}


const moveLayer = (listSourceLayer, indice) => {
  map.moveLayer(`${listSourceLayer[indice]}`);
}


export { orderingLayer }
