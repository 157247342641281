const insecable = () => {
  const regex = /!!!/g;
  if (document.querySelector('.trix-content')) {
    const trixContent = document.querySelector('.trix-content').innerText
    if (document.getElementById('sheet-portrait-show')) {
      document.querySelector('.trix-content').innerHTML = document.querySelector('.trix-content').innerHTML.replace(regex, `&nbsp;`);
    }
  }
  if (document.querySelector('.sheet-portrait-preamble')){
    document.querySelector('.sheet-portrait-preamble').innerHTML = document.querySelector('.sheet-portrait-preamble').innerHTML.replace(regex, `&nbsp;`);
  }
}

export { insecable }
