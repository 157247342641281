const manageNavbar = () => {
  let width = '';
  window.addEventListener('resize', (event) => {
    width = event.currentTarget.innerWidth;
    checkWidth(width);
  })
  width = window.innerWidth;
  checkWidth(width);
}

const checkWidth = (width) => {
  const navbarDesktop = document.querySelector('.navbar-desktop')
  const navbarMobile = document.querySelector('.navbar-mobile')
  if (width < 990) {
  activeNavbar({"navbarDesktop": navbarDesktop,
  "navbarMobile": navbarMobile,
  "active": "mobile"})
  } else {
  activeNavbar({"navbarDesktop": navbarDesktop,
  "navbarMobile": navbarMobile,
  "active": "desktop"})
  }
}

const activeNavbar = (params) => {
  if (params.active == 'desktop') {
    params.navbarDesktop.classList.remove('d-none')
    params.navbarMobile.classList.add('d-none')
  } else if (params.active == 'mobile') {
    params.navbarDesktop.classList.add('d-none')
    params.navbarMobile.classList.remove('d-none')
  }

}



export { manageNavbar }
