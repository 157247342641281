import { breakpoints } from './breakpoints.js'

const reload = () => {
  const map = document.getElementById('map');
  if (map) {
    const width_break = breakpoints();

    if (window.innerWidth <= width_break) {
      document.querySelector('html').dataset.devise = 'mobile';
    } else {
      document.querySelector('html').dataset.devise = 'desktop';
    }
    window.addEventListener('resize', (event) => {
      const width = event.currentTarget.innerWidth;
      const devise = document.querySelector('html').dataset.devise;
      if (width <= width_break) {

        if (devise == 'desktop') {
          reloadOrNo('mobile');
        };
      } else {

        if (devise == 'mobile') {
          reloadOrNo('desktop');
        }
      };
    });

    if (window.location.search.includes('devise=mobile') && window.innerWidth > width_break) {
      reloadOrNo('desktop');
    }
  }
}

const reloadOrNo = (params) => {
  let url = location.origin + location.pathname
  if (location.search.includes(params)) {
    return true
  } else {
    url = url + "?" + "devise=" + `${params}`
    window.location = url
  }
}


export { reload }
