
import { manageNavbar } from 'components/navbar/manageNavbar.js';
import { mobileMenu } from 'components/navbar/mobileMenu.js';
import { responsive } from 'responsive/responsive.js';
import { imageAnimate } from 'components/menu/level1/cat1.js'
import { orderingLayer } from 'components/map/orderingLayer.js';
import { insecable } from '../utilities/insecable.js';
import { interrupteur } from '../utilities/interrupteur.js';
import { cookiesRemove, cookiesAccept } from 'components/cookie/remove.js'
import { initSortable } from "../plugins/init_sortable"


document.addEventListener('turbolinks:load', () => {
  cookiesRemove();
  cookiesAccept();
  insecable();
  // initSortable()

  manageNavbar();
  mobileMenu();
  responsive();
  imageAnimate();
  orderingLayer();
  interrupteur();

})
