import { accessibility } from '../../map/accessibility.js';

const displayMenuSub2 = (items,itemElement) => {

  const clickOnSub2 = document.querySelector(`.${itemElement.dataset.catCatchMenu}`)
  const clickOnSub2To3 = document.getElementById(`${itemElement.dataset.catToSub3Id}`)
  const deep1 = itemElement.dataset.deepCat1
  const deep2 = itemElement.dataset.deepSub2
  const deep3 = itemElement.dataset.deepSub3
  items.forEach((item) => {

    if (clickOnSub2.classList.contains(item.dataset.catCatchMenu)) {

      if (deep1 == 'true') {
        const catId = item.dataset.catCatchMenu
        const menu = document.getElementById(`level-1-${catId}`)

        menu.classList.toggle('d-none')

      } else if (deep2 == 'true') {
        const catId = item.dataset.catCatchMenu
        const menu = document.getElementById(`level-2-${catId}`)
        menu.classList.toggle('d-none')

      } else if (deep3 == 'true') {

        const catId = item.dataset.catCatchMenu
        const menu = document.getElementById(`level-3-${catId}`)
        menu.classList.toggle('d-none')
      } else {

      }
      accessibilityBtn({"element": item, "active": true})
    } else {
      disableItemNoClick(item)
      accessibilityBtn({"element": item, "active": false})

    }
  })
}

const disableItemNoClick = (item) => {
  const deep1 = item.dataset.deepCat1
  const deep2 = item.dataset.deepSub2
  const deep3 = item.dataset.deepSub3

  if (deep1 == 'true') {
      const catId = item.dataset.catCatchMenu
      const menu = document.getElementById(`level-1-${catId}`)
      menu.classList.add('d-none')
  } else if (deep2 == 'true') {
      const catId = item.dataset.catCatchMenu
      const menu = document.getElementById(`level-2-${catId}`)
      menu.classList.add('d-none')
  } else if (deep3 == 'true') {
      const catId = item.dataset.catCatchMenu
      const menu = document.getElementById(`level-3-${catId}`)
      menu.classList.add('d-none')
  }
  removeSub3Menu();
}

const removeSub3Menu = () => {
  const sub3Menus = document.querySelectorAll('.help-catch-sub3')

  sub3Menus.forEach((sub3Menu) => {
    sub3Menu.classList.add('d-none');
  })
}
const closeMenuS2 = () => {
  const btnCloses = document.querySelectorAll('.btn-closes')
  btnCloses.forEach((btnClose) => {
    btnClose.addEventListener('click', (event) => {
      if (event.currentTarget.classList.contains('custome-btn-closes')) {
         event.currentTarget.parentNode.classList.add('d-none')

         accessibility(event.currentTarget.parentNode, false)
      } else {
        event.currentTarget.parentNode.parentNode.classList.add('d-none')
        accessibility(event.currentTarget.parentNode, false)

      }
    })

  })
}


const accessibilityBtn = (p) => {
  if (p.active == true) {
    p.element.ariaPressed = true
  } else {
    p.element.ariaPressed = false
  }
}

export { displayMenuSub2, closeMenuS2 }
