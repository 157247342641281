import { intervalAnimationMenu } from '../intervalAnimationMenu.js'
import { displayMenuSub2 } from '../level2/sub2.js'
import { displayMenuSub3 } from '../level3/sub3.js'
import { closeMenuS2 } from '../level2/sub2.js'
const imageAnimate = () => {
  const items = document.querySelectorAll('.items')

  if (items.length > 0) {
    intervalAnimationMenu(items)
    displayMenuSub3()
    closeMenuS2()
    items.forEach((item) => {
      item.addEventListener('mouseenter', (event) => {
        displayImgText(item)

      })
      item.addEventListener('mouseleave', (event) => {
        displayImgText(item)
      })

      item.addEventListener('click', (event) => {

        displayMenuSub2(items,event.currentTarget)
      })
    })
  }
}



const displayImgText = (item) => {
  item.classList.toggle('menu-item-none')
  item.classList.toggle('menu-item')

}


export { imageAnimate,displayImgText }
