const cookiesRemove = () => {
  const btnCookieRemove = document.getElementById('cookies-remove')
  const cookiePresent = document.querySelector('html').dataset.cookie
  checkVisibleBannerCookie()
  // setCookie(true)
  btnCookieRemove.addEventListener('click', (event) => {
    deleteAllCookies();
    setCookie(false);
    localStorage.setItem('use_cookie', 'false');
    removeBannerCookie()
  })
}

const cookiesAccept = () => {
  const btnCookieAccepte = document.getElementById('cookies-accepte')
  btnCookieAccepte.addEventListener('click', (event) => {
    setCookie(true);
    localStorage.setItem('use_cookie', 'true');
    removeBannerCookie();
  })
}

const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");
    delete localStorage.use_cookie;
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;";
    }
}


const removeBannerCookie = () => {
  document.querySelector('.fr-consent-banner').classList.add('d-none');
}


const checkVisibleBannerCookie = () => {
  try {
    if (document.cookie.includes('use_cookie') || localStorage.use_cookie === 'true' || localStorage.use_cookie === 'false') {
      removeBannerCookie()
      if (document.cookie === 'use_cookie=false' || localStorage.use_cookie === 'true') {

      }
    } else {
      document.querySelector('.fr-consent-banner').classList.remove('d-none');
    }
  } catch (error) {
    console.log(error);
  }
}

const setCookie = (active) => {
  if (active) {
    document.cookie = `use_cookie=true;${cookieOptions()}`;
    localStorage.setItem('use_cookie', 'true');
  } else {
    document.cookie = `use_cookie=false;${cookieOptions()}`;
    localStorage.setItem('use_cookie', 'false');
  }
}

const cookieOptions = () => {
  return "max-age=3600000;secure;samesite"
}

const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
export { cookiesRemove, cookiesAccept }
