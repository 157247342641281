import { accessibility } from '../../map/accessibility.js';
const displayMenuSub3 = () => {
  const btnGoSub3s = document.querySelectorAll('.btn-go-sub3')

  if (btnGoSub3s.length > 0) {
    btnGoSub3s.forEach((btnGoSub3) => {
      btnGoSub3.addEventListener('click', (event) => {

        const sub2InfoId = event.currentTarget.dataset.sub2InfoId
        const sub2 = document.getElementById(sub2InfoId)
        sub2.classList.toggle('d-none')
        if (sub2.classList.contains('d-none')) {
          accessibility(event.currentTarget, false)
        } else {
          accessibility(event.currentTarget, true)
        }
        if (!sub2.classList.contains('left-fix')) {
          sub2.classList.add('left-fix')
        }
      })

    })
  }
}

export { displayMenuSub3 }
