

const menuMobileTilset = () => {
  const menu = document.getElementById('menu-mobile-tilset-mapboxe');
  const btn = document.getElementById('btn-mobile-menu-tilset-mapboxe');
  if (btn) {
    if (menu) {
      btn.addEventListener('click', (event) => {

        btnManageDeactive(btn,menu)
        const itemsMenu = document.getElementById('menu-mobile-tilset-mapboxe-items');
        if (itemsMenu) {
          itemsMenu.classList.toggle('d-none');
          const items = document.querySelectorAll(".mobile-items-cats")
          items.forEach((item) => {
            item.addEventListener('click', (event) => {
              const menu = document.getElementById(`catch-${event.currentTarget.id}`)
              menu.classList.toggle('d-none');
            })
          })
        }
        menuLevel2Or3()
      });
    };
  };
};


const disableMenuItems = () => {
  const btn2 = document.getElementById('btn-pie');
  const menuItems = document.getElementById('menu-mobile-tilset-mapboxe-items');
  btn2.addEventListener('click', (event) => {
    menuItems.classList.toggle('d-none');
  });
};
const btnManageDeactive = (btn, menu) => {
  // menu.classList.toggle('d-none');
  // menu.classList.toggle('animation');
  document.getElementById('map').classList.toggle('opacity-2');
  btn.querySelectorAll('span').forEach((span) => {
    span.classList.toggle('d-none');
  });
};

const menuLevel2Or3 = () => {
  disableMenu2Or3();
  btnDisableMenu2or3();
}
const disableMenu2Or3 = () => {
  const menuLevel2Or3s = document.querySelectorAll('.mobile-menu-level-2-or-3')

  if (menuLevel2Or3s.length > 0) {
    menuLevel2Or3s.forEach((menuLevel2Or3) => {
      if (!menuLevel2Or3.classList.contains('d-none')) {
        menuLevel2Or3.classList.add('d-none');
      }
    })
  }
}
const btnDisableMenu2or3 = () => {
  const btnDisableMenu2or3s = document.querySelectorAll('.mobile-title-tag')
  if (btnDisableMenu2or3s.length > 0) {
    btnDisableMenu2or3s.forEach((btnDisableMenu2or3) => {
      btnDisableMenu2or3.addEventListener('click', (event) => {
        disableMenu2Or3();
      })
    })
  }
}
export { menuMobileTilset }
