const interrupteur = () => {

  const btn = document.getElementById("toggle-698")
  if (btn) {
    btn.addEventListener('change', (event) => {
      if (event.currentTarget.checked) {
        document.getElementById('display-dataview-1').classList.add('d-none-smooth');
        document.getElementById('display-dataview-1').classList.remove('d-block-smooth');

        document.getElementById('display-dataview-2').classList.remove('d-none-smooth');
        document.getElementById('display-dataview-2').classList.add('d-block-smooth');
      } else {
        document.getElementById('display-dataview-1').classList.remove('d-none-smooth');
        document.getElementById('display-dataview-1').classList.add('d-block-smooth');

        document.getElementById('display-dataview-2').classList.add('d-none-smooth');
        document.getElementById('display-dataview-2').classList.remove('d-block-smooth');


      }
    })
  }

}
export { interrupteur }
