import { displayImgText } from './level1/cat1.js'
import { set_storage } from '../set_storage.js'
function lunchDemoImageAnimate(imgs,i) {
  if (i < imgs.length ) {
    displayImgText(imgs[i])
    setTimeout(function() {
      displayImgText(imgs[i]);
    }, 800);
  }

}
const intervalAnimationMenu = (imgs) => {
  let i = -1

  const visiter = localStorage.getItem('visite')
  if (visiter != 'true') {
    set_storage()
    setInterval(function() {
        i = i + 1
       lunchDemoImageAnimate(imgs,i);
     }, 1500);

  }
}

export { intervalAnimationMenu }
