import { reload } from './reload.js';
import { menuMobileTilset } from './menu.js';
import { legendMobile, legendCount } from './legendMobile.js'
const responsive = () => {
  reload();
  menuMobileTilset();
  legendMobile();

}

export { responsive }
