const mobileMenu = () => {
  const iconeMenuOpen = document.querySelector('.ri-menu-fill')
  const iconeMenuClose = document.querySelector('.ri-close-fill')
  // displayMenu(iconeMenuOpen,iconeMenuClose);
  // displayMenu(iconeMenuClose,iconeMenuOpen);
  const menuNavbar = document.getElementById('navigation-773')

  displayMenu2(iconeMenuOpen,menuNavbar);

}
const displayMenu2 = (icone,menuNavbar) => {
  icone.addEventListener('click', (event) => {
    event.currentTarget.ariaHidden = (event.currentTarget.ariaHidden === 'true') ? 'false' : 'true';
    event.currentTarget.ariaExpanded = (event.currentTarget.ariaExpanded === 'true') ? 'false' : 'true';
    menuNavbar.classList.toggle('d-none')

  })
}

// const displayMenu = (icone_1,icone_2) => {
//   icone_1.addEventListener('click', (event) => {
//     const menuNavbar = document.getElementById('navigation-773')
//     menuNavbar.classList.toggle('d-none')
//     event.currentTarget.classList.toggle('d-none')
//     icone_2.classList.toggle('d-none')
//   })
// }

export { mobileMenu }
